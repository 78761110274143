<template lang="pug">
  #test-modal(v-esc-close="cancel")
    .modal-header
      h1 Test Modal

    .modal-text
      p {{ text }}
    .modal-footer
      button.button(@click="cancel") Cancel
      button.button.action-btn(@click="doSomething") Do Something
</template>

<script>
export default {
  props: {
    text: { type: String, required: true },
  },

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {
    doSomething,
    cancel,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
async function doSomething() {
  console.log('TestModal.vue :39', 'did something')
}

function cancel() {
  this.$modal.close('TestModal')
}
</script>
